import { useInView } from "react-intersection-observer";
import {useEffect} from "react";

const TextBlock = ({content, slideNum, setActiveSlide, imageMobile }) => {

    const [ref, inView, entry] = useInView({ threshold: 1 });
    useEffect(() => {
        if (inView) {
            setActiveSlide(slideNum);
        }
    }, [ inView ]);


    return (
        <div className={'home-vertical-carousel__content'} >
            <div className="home-vertical-carousel__text">
                {
                    window.innerWidth < 768 && (
                        <picture data-slide="1" className="home-vertical-carousel__text-img">
                            <source media={"(max-width: 767px)"} srcSet={imageMobile} />
                            <source media={"(min-width: 768px)"} srcSet={imageMobile} />
                            <img width={700} height={657}  src={imageMobile} />
                        </picture>
                    )
                }
                <div ref={ref} className="home-vertical-carousel__text-wrapper" dangerouslySetInnerHTML={{ __html: content }}>
                </div>
            </div>
        </div>
    );
}
export default TextBlock;

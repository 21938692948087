import { useState } from "react";
import {
    motion,
    AnimatePresence,
} from "framer-motion";
import TextBlock from "./TextBlock";
import Background from "./Background";

const App = () => {
    const sliderData = window.homeVerticalCarouselSliderData;
    const [activeSlide, setActiveSlide ] = useState(0);

    return (
        <section className={'home-vertical-carousel-group'} >
            <div className="carousel-backgrounds">
                <AnimatePresence>
                    {sliderData.map(({imageDesktop, imageMobile, id}, i) => (
                        activeSlide === i && (<motion.div
                            key={id}
                            className={'motion-pic-wrapper'}
                            initial={{ scale: .9, opacity:0 }}
                            animate={{ scale: 1, opacity:1 }}
                            exit={{ scale: 1.1, opacity:0 }}
                            transition={{duration: .4, ease: 'easeInOut' }}
                            >
                                <Background imageDesktop={imageDesktop} imageMobile={imageMobile} />
                            </motion.div>)
                        )
                    )}
                </AnimatePresence>
            </div>
            <div className="carousel-texts">
                {sliderData.map(({content, id, imageMobile}, i) => <TextBlock
                    key={id}
                    imageMobile={imageMobile}
                    content={content}
                    slideNum={i}
                    setActiveSlide={setActiveSlide} />)}
            </div>
        </section>
    );
}

export default App;
